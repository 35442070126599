import { Dialog } from '@qasa/app/src/components/dialog'

import {
  DotcomPlatformSelectorDialog,
  shouldDisplayDotcomPlatformSelectorDialog,
} from '../../feature-modules/dotcom-platform-selector-dialog/dotcom-platform-selector-dialog'

import { Login } from './login'

type AuthenticationFlowDialogProps = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export function AuthenticationFlowDialog({ isOpen, onOpenChange }: AuthenticationFlowDialogProps) {
  if (shouldDisplayDotcomPlatformSelectorDialog()) {
    return <DotcomPlatformSelectorDialog isOpen={isOpen} onOpenChange={onOpenChange} />
  }

  return (
    <Dialog size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
      <Login variant={'dialog'} />
    </Dialog>
  )
}
