import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import type { ReactNode } from 'react'

const TRANSITION_DURATION = 0.15

const AnimatedDiv = styled(motion.div)({
  overflow: 'hidden',
})

type ErrorListTransitionProps = {
  children: ReactNode
  shouldShow: boolean
}
export function ErrorListTransition({ children, shouldShow }: ErrorListTransitionProps) {
  return (
    <AnimatePresence>
      {shouldShow && (
        <AnimatedDiv
          key={'error'}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: TRANSITION_DURATION }}
        >
          {children}
        </AnimatedDiv>
      )}
    </AnimatePresence>
  )
}
