import { useTranslation } from 'react-i18next'

import { emailPattern } from '../../helpers/validation'

import { ConnectedTextField } from './components'

export function EmailField() {
  const { t } = useTranslation('authentication_flow')

  return (
    <ConnectedTextField
      name="email"
      label={t('email.label')}
      type="email"
      autoComplete="username"
      options={{
        required: { value: true, message: t('validation.email.required') },
        pattern: { value: emailPattern, message: t('validation.email.pattern') },
      }}
    />
  )
}
