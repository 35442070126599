import { Spacer, Stack, Link, Paragraph, Button } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Link as RouterLink } from '../../../vendor/next'
import { useAuthContext } from '../../../context/auth-context'
import { P1 } from '../../../helpers/p1-api'
import type { ApiError } from '../error-list'
import { ErrorListTransition } from '../error-list-transition'
import { ErrorList } from '../error-list'
import { EmailField } from '../email-field'
import { getPath } from '../../../routing/get-path'
import { ConnectedTextField } from '../components'

const CenteredButton = styled(Button)({
  alignSelf: 'center',
})

type LoginFormValues = {
  email: string
  password: string
}
export function LoginForm() {
  const { t } = useTranslation('authentication_flow')
  const { setAccessTokenAndFetchUser } = useAuthContext()
  const [errors, setErrors] = useState<ApiError[]>([])
  const hasErrors = Boolean(errors.length > 0)
  const handleUnexpectedError = () => setErrors([{ code: 'unknown_error' }])

  const submitForm = async (formValues: LoginFormValues) => {
    const { email, password } = formValues
    try {
      const { accessToken, status } = await P1.login({ user: { email, password } })
      if (status === 200) {
        setErrors([])
        setAccessTokenAndFetchUser(accessToken)
      } else {
        handleUnexpectedError()
      }
    } catch (error: LegitimateAny) {
      const apiErrors = error?.response?.data?.errors
      if (apiErrors) {
        setErrors(apiErrors)
      } else {
        handleUnexpectedError()
      }
    }
  }
  const methods = useForm<LoginFormValues>({
    /**
     * Using onSubmit instead of `onTouched` to avoid
     * triggering validation when navigating away from auth dialog.
     * (first input gets autofocussed)
     */
    mode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  })
  const shouldShowErrors = hasErrors && !methods.formState.isSubmitting

  return (
    <div>
      <ErrorListTransition shouldShow={shouldShowErrors}>
        <ErrorList errors={errors} />
      </ErrorListTransition>
      <Spacer size={'4x'} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitForm)} noValidate>
          <Stack gap={'4x'}>
            <EmailField />
            <ConnectedTextField
              name="password"
              label={t('password.label')}
              type="password"
              autoComplete={'currentPassword'}
              options={{
                required: { value: true, message: t('validation.password.required') },
              }}
            />
            <Paragraph size="sm">
              <Link as={RouterLink} href={getPath('resetPasswordRequest')}>
                {t('forgot_password_link_text')}
              </Link>
            </Paragraph>
            <CenteredButton
              type="submit"
              variant={'primary'}
              isDisabled={methods.formState.isSubmitting}
              isLoading={methods.formState.isSubmitting}
            >
              {t('login.submit')}
            </CenteredButton>
          </Stack>
        </form>
      </FormProvider>
    </div>
  )
}
