import type { Region } from '@qasa/app/src/contexts/region'
import { getRootUrl } from '@qasa/app/src/configs/region-config'
import { Button, Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Dialog } from '@qasa/app/src/components/dialog'
import { ampliExperiment } from '@qasa/ampli/p2'
import { useSearchParams } from 'next/navigation'

import { currentBrand } from '../../config'
import { usePathname } from '../../vendor/next'

const BlurredBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backdropFilter: 'blur(10px)',
  zIndex: theme.zIndices.modal,
}))

export const shouldDisplayDotcomPlatformSelectorDialog = () => {
  const shouldBypassSelector = ampliExperiment.getVariant('dotcom-bypass-country-selector')?.value === 'on'
  // NOTE: Do not remove until the we have a solution for login/signup for qasa.com for all brands.
  if (shouldBypassSelector) {
    return false
  }

  return currentBrand === 'dotcom'
}

type DotcomPlatformSelectorDialogProps = {
  pathnameOverrides?: Partial<Record<Region, string>>
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}
export function DotcomPlatformSelectorDialog({
  pathnameOverrides,
  isOpen = true,
  onOpenChange,
}: DotcomPlatformSelectorDialogProps) {
  const { t } = useTranslation('dotcom_platform_selector_dialog')
  const pathname = usePathname()
  const searchParams = useSearchParams().toString()

  const availableCountryPlatforms: Region[] = ['se', 'fi', 'fr']

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange?.(isOpen)
  }
  return (
    <>
      {isOpen && <BlurredBackground />}
      <Dialog
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        size={'sm'}
        isCloseButtonVisible={Boolean(onOpenChange)}
      >
        <Dialog.Header hasSidePadding={true}>
          <Heading>{t('heading')}</Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap={'2x'}>
            {availableCountryPlatforms.map((countryCode) => {
              const destinationPathname = pathnameOverrides?.[countryCode] ?? pathname
              const searchString = searchParams ? `?${searchParams}` : ''
              return (
                <Button
                  as={'a'}
                  variant={'tertiary'}
                  href={`${getRootUrl(countryCode)}${destinationPathname}${searchString}`}
                  key={countryCode}
                >
                  {t(`commons:countries.${countryCode}`)}
                </Button>
              )
            })}
          </Stack>
        </Dialog.Body>
      </Dialog>
    </>
  )
}
