import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

import { AuthenticationFlowDialog } from '../ui-page-modules/authentication-flow/authentication-flow-dialog'
import { usePathname } from '../vendor/next'

type AuthDialogContextValues = {
  isAuthDialogOpen: boolean
  setIsAuthDialogOpen: Dispatch<SetStateAction<boolean>>
}
const AuthDialogContext = createContext<AuthDialogContextValues>({
  isAuthDialogOpen: false,
  setIsAuthDialogOpen: () => null,
})

function AuthDialogContextProvider({ children }: { children: ReactNode }) {
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false)

  const pathname = usePathname()

  // Close the dialog when the user navigates to a different page.
  // E.g. when the user clicks the signup link in the modal.
  useEffect(() => {
    setIsAuthDialogOpen(false)
  }, [pathname])

  return (
    <AuthDialogContext.Provider value={{ isAuthDialogOpen, setIsAuthDialogOpen }}>
      {children}
      <AuthenticationFlowDialog isOpen={isAuthDialogOpen} onOpenChange={setIsAuthDialogOpen} />
    </AuthDialogContext.Provider>
  )
}

const useAuthDialogContext = () => useContext(AuthDialogContext)

export { useAuthDialogContext, AuthDialogContextProvider }
