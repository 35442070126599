import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { AlertCircleIcon, Label, Stack } from '@qasa/qds-ui'

const ErrorsWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.core.red10,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.xs,
}))

export type ApiError = {
  code: string
  message?: string
}

type ErrorListProps = { errors: ApiError[] }
export function ErrorList({ errors }: ErrorListProps) {
  const { t } = useTranslation('authentication_flow')
  return (
    <ErrorsWrapper>
      {errors.map((error) => (
        <Stack direction="row" alignItems="center" gap="3x" key={error.code}>
          <AlertCircleIcon size={16} color="negative" />
          {/* fall back to the API message if no code translation is available */}
          <Label size="sm">{t([`rest_api_errors.${error.code}`, error.message || 'unknown_error'])}</Label>
        </Stack>
      ))}
    </ErrorsWrapper>
  )
}
