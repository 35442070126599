import type { SelectProps, TextFieldProps } from '@qasa/qds-ui'
import { Select, Spacer, Heading, TextField } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import type { RegisterOptions } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import styled from '@emotion/styled'
import { Dialog } from '@qasa/app/src/components/dialog'

import { NAV_HEIGHT } from '../top-nav-bar/constants'

import type { AuthenticationSectionProps } from './authentication-section.types'
import type { SignupFormValues } from './signup/signup-form'
import type { PostSignupFormValues } from './post-signup/post-signup'

type Names = keyof PostSignupFormValues | keyof SignupFormValues
type ConnectedTextFieldProps<T extends Names> = Omit<TextFieldProps, 'isInvalid' | 'errorMessage'> & {
  name: NoInfer<T>
  options?: RegisterOptions
}
export function ConnectedTextField<T extends Names>({
  label,
  name,
  options,
  ...props
}: ConnectedTextFieldProps<T>) {
  const { register, formState } = useFormContext<PostSignupFormValues | SignupFormValues>()

  const errorMessage = formState.errors[name]?.message
  const isInvalid = Boolean(errorMessage)

  return (
    <TextField
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      {...register(name, options)}
      {...props}
    />
  )
}

type ConnectedSelectProps = Omit<SelectProps, 'isInvalid' | 'errorMessage'> & {
  name: keyof PostSignupFormValues
  options?: RegisterOptions
}
export function ConnectedSelect({ label, name, options, ...props }: ConnectedSelectProps) {
  const { register, formState } = useFormContext<PostSignupFormValues>()

  const errorMessage = formState.errors[name]?.message
  const isInvalid = Boolean(errorMessage)

  return (
    <Select
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      {...register(name, options)}
      {...props}
    />
  )
}

export function SectionHeading({ variant, children }: AuthenticationSectionProps & { children: ReactNode }) {
  if (variant === 'dialog') {
    return (
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{children}</Dialog.Title>
      </Dialog.Header>
    )
  }
  return (
    <Heading as="h1" size="lg">
      {children}
    </Heading>
  )
}

export function SectionBody({ variant, children }: AuthenticationSectionProps & { children: ReactNode }) {
  if (variant === 'dialog') {
    return <Dialog.Body>{children}</Dialog.Body>
  }
  return (
    <>
      <Spacer size="4x" />
      {children}
    </>
  )
}

export const AuthenticationPageWrapper = styled.div({
  paddingTop: 48,
  maxWidth: 400,
  margin: '0 auto',
  minHeight: `calc(100vh - ${NAV_HEIGHT}px)`,
})
